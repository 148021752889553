
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  loanAmount: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const submitButton1 = ref<HTMLElement | null>(null);
    const invProjCalculated = false;

    const yearlyPlanSchedule = {
      ofTerm: "",
      nominal: {
        accumulatedDepositsBF: 0,
        additionalContribution: 0,
        dividend: 0,
        accumulatedDepositsCF: 0,
      },
      withInflation: {
        accumulatedDepositsRealBF: 0,
        additionalContribution: 0,
        dividendWithInflation: 0,
        accumulatedDepositsRealCF: 0,
      },
    };

    const totalPeriods = [
      {
        value: 1,
        label: "Annually",
      },
      // {
      //   value: 4,
      //   label: "Quarterly",
      // },
      {
        value: 12,
        label: "Monthly",
      },
      {
        value: 52.14,
        label: "Weekly",
      },
      {
        value: 365,
        label: "Daily",
      },
    ];

    const plan = [];

    const totalAdditionalContribution = 0;
    const totalReturnOrInterestNominal = 0;
    const totalReturnOrInterestWithInflation = 0;
    const totalInvestmentNominal = 0;
    const totalInvestmentWithInflation = 0;
    const contibutionMode = "end";

    return {
      totalPeriods,
      id: route.params.id ?? null,
      store: useStore(),
      submitButton1,
      invProj: {
        calculator_type_id: 11,
        raw: {
          startingDeposit: 10000,
          annualDividendRate: 5,
          period: 1,
          duration: 10,
          annualFlatInflationRate: 2,
          additionalContribution: 1000,
          adjustedRateOfReturn: 2.94,
        },
      },
      yearlyPlanSchedule,
      plan,
      invProjCalculated,
      totalAdditionalContribution,
      totalReturnOrInterestNominal,
      totalReturnOrInterestWithInflation,
      totalInvestmentNominal,
      totalInvestmentWithInflation,
      contibutionMode,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const investmentValidator = Yup.object({
      raw: Yup.object({
        startingDeposit: Yup.string().required().label("Existing EPF balance"),
        annualDividendRate: Yup.string().required().label("Annual Salary"),
        period: Yup.string().required().label("Current Annual EPF"),
        annualFlatInflationRate: Yup.number()
          .required()
          .label("Growth rate of salary "),
        additionalContribution: Yup.number()
          .required()
          .label("Rate of Dividend "),
        adjustedRateOfReturn: Yup.number()
          .required()
          .label("Rate of contribution to EPF "),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Investment Projection", [
        "Financial Calculators",
      ]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      investmentValidator,
      translate,
      nFormat,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.invProj = this.store.getters.getCalculatorData.raw;
          this.calculate();
        });
      }
    },
    calculateRateOfReturn() {
      let anlDivRate = 1 + this.invProj.raw.annualDividendRate / 100;
      let anlFlatInflationRate =
        1 + this.invProj.raw.annualFlatInflationRate / 100;
      let adjustedRateOfReturn = (anlDivRate / anlFlatInflationRate - 1) * 100;
      this.invProj.raw.adjustedRateOfReturn = adjustedRateOfReturn;
    },
    setDurationLimit(e) {
      console.log(this.invProj.raw.period);
    },
    calculate() {
      this.plan = [];
      let annualDividendRate = this.invProj.raw.annualDividendRate / 100;
      let DividendRate = annualDividendRate / this.invProj.raw.period;

      let addjustedRate = this.invProj.raw.adjustedRateOfReturn / 100;
      let addjustedDividendRate = addjustedRate / this.invProj.raw.period;

      let accumulatedDepositsBF = this.invProj.raw.startingDeposit;
      let additionalContribution = this.invProj.raw.additionalContribution;

      let dividend = 0;
      if (this.contibutionMode == "end") {
        dividend = accumulatedDepositsBF * DividendRate;
      } else {
        dividend =
          (accumulatedDepositsBF + additionalContribution) * DividendRate;
      }

      let accumulatedDepositsCF =
        accumulatedDepositsBF + additionalContribution + dividend;

      let accumulatedDepositsRealBF = this.invProj.raw.startingDeposit;
      let additionalContributionI = this.invProj.raw.additionalContribution;

      let dividendWithInflation = 0;
      if (this.contibutionMode == "end") {
        dividendWithInflation =
          accumulatedDepositsRealBF * addjustedDividendRate;
      } else {
        dividendWithInflation =
          (accumulatedDepositsRealBF + additionalContributionI) *
          addjustedDividendRate;
      }

      let accumulatedDepositsRealCF =
        accumulatedDepositsRealBF +
        additionalContributionI +
        dividendWithInflation;

      let year = this.ordinal_suffix_of(1);

      this.yearlyPlanSchedule = {
        ofTerm: year,
        nominal: {
          accumulatedDepositsBF: accumulatedDepositsBF,
          additionalContribution: additionalContribution,
          // dividend: parseFloat(dividend),
          dividend: dividend,
          accumulatedDepositsCF: accumulatedDepositsCF,
        },
        withInflation: {
          accumulatedDepositsRealBF: accumulatedDepositsRealBF,
          additionalContribution: additionalContributionI,
          // dividendWithInflation: parseFloat(dividendWithInflation),
          dividendWithInflation: dividendWithInflation,
          accumulatedDepositsRealCF: accumulatedDepositsRealCF,
        },
      };
      this.totalAdditionalContribution = additionalContribution;
      this.totalReturnOrInterestNominal = dividend;
      this.totalReturnOrInterestWithInflation = dividendWithInflation;

      this.plan.push(this.yearlyPlanSchedule);

      for (let i = 1; i < this.invProj.raw.duration; i++) {
        let year = this.ordinal_suffix_of(i + 1);
        accumulatedDepositsBF = accumulatedDepositsCF;
        additionalContribution = this.invProj.raw.additionalContribution;
        if (this.contibutionMode == "end") {
          dividend = accumulatedDepositsBF * DividendRate;
        } else {
          dividend =
            (accumulatedDepositsBF + additionalContribution) * DividendRate;
        }

        accumulatedDepositsCF =
          accumulatedDepositsBF + additionalContribution + dividend;

        accumulatedDepositsRealBF = accumulatedDepositsRealCF;
        additionalContributionI = this.invProj.raw.additionalContribution;
        if (this.contibutionMode == "end") {
          dividendWithInflation =
            accumulatedDepositsRealBF * addjustedDividendRate;
        } else {
          dividendWithInflation =
            (accumulatedDepositsRealBF + additionalContributionI) *
            addjustedDividendRate;
        }

        accumulatedDepositsRealCF =
          accumulatedDepositsRealBF +
          additionalContributionI +
          dividendWithInflation;

        this.yearlyPlanSchedule = {
          ofTerm: year,
          nominal: {
            accumulatedDepositsBF: accumulatedDepositsBF,
            additionalContribution: additionalContribution,
            dividend: dividend,
            accumulatedDepositsCF: accumulatedDepositsCF,
          },
          withInflation: {
            accumulatedDepositsRealBF: accumulatedDepositsRealBF,
            additionalContribution: additionalContributionI,
            // dividendWithInflation: parseFloat(dividendWithInflation),
            dividendWithInflation: dividendWithInflation,
            accumulatedDepositsRealCF: accumulatedDepositsRealCF,
          },
        };

        this.totalAdditionalContribution += additionalContribution;
        this.totalReturnOrInterestNominal += dividend;
        this.totalReturnOrInterestWithInflation += dividendWithInflation;

        this.plan.push(this.yearlyPlanSchedule);
      }

      this.totalInvestmentNominal =
        this.invProj.raw.startingDeposit +
        this.totalAdditionalContribution +
        this.totalReturnOrInterestNominal;
      this.totalInvestmentWithInflation =
        this.invProj.raw.startingDeposit +
        this.totalAdditionalContribution +
        this.totalReturnOrInterestWithInflation;

      this.invProjCalculated = true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    onSubmit(values) {
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
